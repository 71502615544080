<template>
  <div class="list-approval-page">
    <KTCodePreview v-bind:title="'Danh sách phiếu yêu cầu duyệt chi'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5">
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span class="py-2 px-3">
                  <i style="font-size: 1rem" class="far fa-file-excel "></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="search-col">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedType"
              :options="listType"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
          <b-col>
            <Autosuggest
              class="border-radius-none"
              :model="searchStore"
              :suggestions="filteredOptionStore"
              placeholder="cửa hàng"
              :limit="200"
              @select="onSelectedStore"
              @change="onInputChangeStore"
              suggestionName="suggestionName"
              :disabled="disableStore"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input
              v-model="searchCode"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-input
              v-model="entityId"
              type="text"
              placeholder="ID chứng từ"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <Autosuggest
              class="border-radius-none"
              :model="searchEmployee"
              :suggestions="filteredEmployeeOptions"
              placeholder="người tạo"
              :limit="200"
              @select="onSelectedEmployee"
              @change="onSearchEmployeeChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedPaymentType"
              :options="listPaymentType"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(code)="row">
            <div
              style="justify-content: left; align-items: center; display: grid"
            >
              <a class="font-weight-bold" v-bind:href="getLinkDoc(row.item)">
                {{ row.item.code }}
              </a>
              <span v-text="row.item.store?.name"></span>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <span
              style="width: max-content"
              v-text="getApprovalStatusName(row.item.status)"
              v-bind:class="bindClassNameStatus(row.item.status)"
            ></span>
            <span
              v-if="showCancelStatus(row.item)"
              class="d-flex justify-content-center"
            >
              ({{ row.item.failReason }})
            </span>
          </template>
          <template v-slot:cell(type)="row">
            <p class="text-center mb-0">
              {{ getApprovalTypeName(row.item.type) }}
            </p>
            <p class="text-center text-primary">{{ row.item.entityId }}</p>
          </template>
          <template v-slot:cell(customerName)="row">
            <p class="mb-0" style="font-weight: 550">
              {{ row.item.customerName }}
            </p>
            <p
              class="mb-0"
              style="
                white-space: normal;
                color: lightslategray;
                font-size: 13px;
                font-weight: 500;
              "
            >
              {{ row.item.customerMobile }}
            </p>
            <div
              v-if="
                row.item.customerBankName &&
                row.item.customerBankName.trim().length > 0
              "
            >
              <b>NH:</b>
              {{ row.item.customerBankName }}
            </div>
            <div
              v-if="
                row.item.customerBankNo &&
                row.item.customerBankNo.trim().length > 0
              "
            >
              <b>STK:</b>
              {{ row.item.customerBankNo }}
            </div>
            <div
              v-if="
                row.item.customerAccountName &&
                row.item.customerAccountName.trim().length > 0
              "
            >
              <b>Tên TK:</b>
              {{ row.item.customerAccountName }}
            </div>
          </template>
          <template v-slot:cell(createdByName)="row">
            <p class="text-center">{{ row.item.creator?.fullName }}</p>
            <p class="text-center">
              {{ formatDate(row.item.createdAt, 'DD/MM/YYYY HH:mm') }}
            </p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="text-center">
              <i
                class="fa fa-download text-primary cursor-pointer mb-4"
                aria-hidden="true"
                @click="openFileModal(row.item)"
              ></i>
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkPermission('APPROVAL_UPDATE')"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkPermission('APPROVAL_UPDATE')"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showApproveModal(row.item.id)"
                  v-if="isShowApprove(row.item.status)"
                >
                  <span class="dropdown-item-text">
                    <i class="fa fa-angle-double-right"></i>
                    &nbsp; Duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showChangeStatusModal(row.item)"
                  v-if="isShowChangeStatus(row.item.status)"
                >
                  <span class="dropdown-item-text">
                    <i
                      style="font-size: 1rem"
                      class="fas fa-sync-alt icon-color"
                    ></i>
                    &nbsp; Đổi trạng thái
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showWarningAlert(row.item, 'return')"
                  v-if="isShowReturnRequest(row.item.status)"
                >
                  <span class="dropdown-item-text">
                    <i style="font-size: 1rem" class="flaticon2-reply-1"></i>
                    &nbsp; Hoàn duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showWarningAlert(row.item, 'delete')"
                  v-if="isShowDelete(row.item.status)"
                >
                  <span class="dropdown-item-text">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-if="totalPages"
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <ApproveModal
          ref="popup-approve"
          v-on:submit-data-success="fetchData"
        />
        <ApprovalChangeStatus
          ref="approval-change-status"
          v-on:submit-data-success="fetchData"
        />
        <template>
          <b-modal
            ref="file-modal"
            size="lg"
            hide-footer
            title="Chỉnh sửa tệp"
            id="file-modal"
          >
            <b-row>
              <b-col cols="12">
                <UploadFile :id="entityUploadId" :entity="'purchase-orders'" />
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import localData from '@/utils/saveDataToLocal';
import { APPROVAL_TYPE, APPROVAL_PAYMENT_TYPE } from '@/utils/constants';
import 'vue-cute-timeline/dist/index.css';
import {
  xoa_dau,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  cloneDeep,
  formatDate,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import timeUtils from '@/utils/date';
import ApproveModal from '../../components/approval/ApproveModal.vue';
import ApprovalChangeStatus from '../../components/approval/ApprovalChangeStatus.vue';
import { APPROVAL_STEP_STATUS } from '@/utils/enum';
import { APPROVAL_STATUS } from '@/utils/constants';
import UploadFile from '@/view/modules/upload/Upload';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import fileDownload from '@/utils/file-download';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: timeUtils.DP_CONFIG.date,
      onLoading: false,
      listItem: [],
      searchDescription: '',
      searchStore: '',
      searchCode: '',
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'type',
          label: 'Loại phiếu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'amount',
          label: 'Số tiền',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'paymentType',
          label: 'Hình thức thanh toán',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          formatter: (value) => {
            return this.getApprovalPaymentTypeName(value);
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selectedStoreId: null,
      optionsStore: [],
      filteredOptionStore: [],
      limit: 10,
      selectedStatus: null,
      listStatus: APPROVAL_STATUS,
      selectedType: null,
      listType: APPROVAL_TYPE,
      selectedEmployeeId: null,
      optionsEmployee: [],
      filteredEmployeeOptions: [],
      searchEmployee: '',
      disableStore: false,
      selectedPaymentType: null,
      listPaymentType: APPROVAL_PAYMENT_TYPE,
      entityUploadId: null,
      entityId: '',
    };
  },
  methods: {
    convertPrice,
    formatDate,
    fetchStore: async function () {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;

          if (stores.length === 1) {
            this.selectedStoreId = stores[0].id;
            this.searchStore = stores[0].name;
            this.disableStore = true;
          } else {
            this.optionsStore = stores.map((element) => {
              return {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
            });
            this.filteredOptionStore = [...this.optionsStore];
          }
          this.fetchData();
        }
      });
    },
    fetchData: async function () {
      this.onLoading = true;

      const entityId = this.$route.query.entityId;

      if(entityId) {
        this.entityId = entityId;
      }

      const params = this.getParamFilter();

      ApiService.query('approvals', { params })
        .then((response) => {
          const responseData = response.data.data;

          this.totalPages = responseData.paginations.totalPages;
          this.totalItems = responseData.paginations.total;
          this.listItem = responseData.data.map((item, index) => {
            return {
              ...item,
              count: index + 1,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'info-approval',
        query: { id: item.id },
      });
    },
    onSelectedStore(option) {
      this.selectedStoreId = option.item.id;
      this.searchStore = option.item.name;
    },
    getApprovalPaymentTypeName(paymentType) {
      const paymentTypeInfo = APPROVAL_PAYMENT_TYPE.find(
        (type) => type.id === paymentType,
      );
      return paymentTypeInfo
        ? paymentTypeInfo.name
        : 'Không tìm thấy hình thức thanh toán';
    },
    getApprovalStatusName(status) {
      const statusInfo = APPROVAL_STATUS.find((type) => type.id === status);
      return statusInfo ? statusInfo.name : 'Không tìm thấy trạng thái';
    },
    getApprovalTypeName(type) {
      const typeInfo = APPROVAL_TYPE.find((item) => item.id === type);
      return typeInfo ? typeInfo.name : 'Không tìm thấy trạng thái';
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionStore = [...filteredData];
    },
    showWarningAlert: function (item, action = 'delete') {
      const title =
        action === 'return'
          ? 'Hoàn duyệt yêu cầu duyệt chi!'
          : 'Xóa phiếu yêu cầu duyệt chi!';
      const text =
        action === 'return'
          ? 'Bạn có chắc muốn hoàn duyệt yêu cầu duyệt duyệt chi này?'
          : 'Bạn có chắc muốn xóa phiếu yêu cầu duyệt chi này?';
      const confirmText = action === 'return' ? 'Đồng ý' : 'Xóa';
      const optionsNotify = {
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: confirmText,
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      };

      Swal.fire(optionsNotify).then((result) => {
        if (result.value) {
          action === 'return'
            ? this.cancelRequest(item)
            : this.deleteItem(item);
        }
      });
    },
    cancelRequest: async function (item) {
      const url = `approvals/${item.id}/return`;
      const method = 'put';
      ApiService[method](url)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.fetchData();
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message || 'Lỗi!';
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    deleteItem: async function (item) {
      ApiService.delete(`approvals/${item.id}`).then(() => {
        makeToastSuccess('Xoá phiếu yêu cầu duyệt chi thành công!');
        this.fetchData();
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-approval',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getLinkDoc(item) {
      return `#/approval/info?id=${item.id}`;
    },
    onSelectedEmployee(option) {
      this.selectedEmployeeId = option.item.id;
      this.searchEmployee = option.item.name;
    },
    onSearchEmployeeChange(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredEmployeeOptions = [...filteredData];
    },
    fetchEmployee: async function () {
      ApiService.get('/users/list').then((response) => {
        const employees = response.data.data.data;
        this.optionsEmployee = employees.map((element) => {
          let suggestionName = element.fullName;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.fullName);
          return {
            id: element.id,
            name: element.fullName,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredEmployeeOptions = [...this.optionsEmployee];
      });
    },
    showApproveModal(approvalId) {
      this.$refs['popup-approve'].showModal(approvalId);
    },
    bindClassNameStatus(approvalStatus) {
      let className = '';
      switch (approvalStatus) {
        case APPROVAL_STEP_STATUS.NEW: {
          className =
            'label font-weight-bold label-lg label-inline label-light-warning';
          break;
        }
        case APPROVAL_STEP_STATUS.APPROVED: {
          className = 'badge badge-success';
          break;
        }
        case APPROVAL_STEP_STATUS.CANCELED: {
          className = 'badge badge-dark';
          break;
        }
        case APPROVAL_STEP_STATUS.PAID: {
          className = 'badge badge-secondary';
          break;
        }
        case APPROVAL_STEP_STATUS.PAY_FAILED: {
          className = 'badge badge-warning';
          break;
        }
        case APPROVAL_STEP_STATUS.COMPLETED: {
          className = 'badge badge-info';
          break;
        }
        case APPROVAL_STEP_STATUS.ON_APPROVE: {
          className = 'badge badge-danger text-dark';
          break;
        }
        case APPROVAL_STEP_STATUS.CHECKED: {
          className = 'badge badge-warning text-white';
          break;
        }
      }
      return className;
    },
    showChangeStatusModal(approvalModel) {
      this.$refs['approval-change-status'].showModal(approvalModel);
    },
    isShowChangeStatus(approvalStatus) {
      if (!this.checkPermission('APPROVAL_UPDATE')) {
        return false;
      }
      const acceptStatusChanges = [
        APPROVAL_STEP_STATUS.APPROVED,
        APPROVAL_STEP_STATUS.PAID,
        APPROVAL_STEP_STATUS.COMPLETED,
      ];
      return acceptStatusChanges.includes(approvalStatus);
    },
    isShowApprove(approvalStatus) {
      if (!this.checkPermission('APPROVAL_APPROVE')) {
        return false;
      }
      return (
        approvalStatus === APPROVAL_STEP_STATUS.NEW ||
        approvalStatus === APPROVAL_STEP_STATUS.ON_APPROVE
      );
    },
    isShowDelete(approvalStatus) {
      if (!this.checkPermission('APPROVAL_DELETE')) {
        return false;
      }
      return approvalStatus === APPROVAL_STEP_STATUS.NEW;
    },
    isShowReturnRequest(approvalStatus) {
      if (!this.checkPermission('APPROVAL_APPROVE')) {
        return false;
      }
      return (
        approvalStatus === APPROVAL_STEP_STATUS.APPROVED ||
        approvalStatus === APPROVAL_STEP_STATUS.ON_APPROVE
      );
    },
    showCancelStatus(item) {
      return item.failReason && item.status === APPROVAL_STEP_STATUS.PAY_FAILED;
    },
    openFileModal(item) {
      this.entityUploadId = item.code;
      this.$refs['file-modal'].show();
    },
    reportClick() {
      const params = this.getParamFilter();
      const url = `${BASE_URL}approvals/export-excel`;
      axios
        .get(url, {
          params: {
            ...params,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }

          this.$nprogress.done();
        });
    },
    getParamFilter() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      if (!this.searchStore || this.searchStore.trim() === '') {
        this.selectedStoreId = null;
      }

      if (!this.searchEmployee || this.searchEmployee.trim() === '') {
        this.selectedEmployeeId = null;
      }

      return {
        page: this.page,
        limit: 10,
        description: this.searchDescription
          ? this.searchDescription.trim()
          : '',
        code: this.searchCode ? this.searchCode.trim() : '',
        entityId: this.entityId ? this.entityId.trim() : '',
        status: this.selectedStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        storeId: this.selectedStoreId,
        type: this.selectedType,
        paymentType: this.selectedPaymentType,
        employeeId: this.selectedEmployeeId,
      };
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu duyệt chi', route: 'purchase-orders' },
      { title: 'Danh sách phiếu yêu cầu duyệt chi' },
    ]);
  },
  created() {
    this.listType = cloneDeep(APPROVAL_TYPE);
    this.listType.unshift({
      id: null,
      name: 'Tất cả loại duyệt chi',
    });
    this.fetchStore();
    this.fetchEmployee();
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    Autosuggest,
    ApproveModal,
    ApprovalChangeStatus,
    UploadFile,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
</style>
<style lang="scss">
.list-approval-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
  .dropdown-item {
    padding: 0px;
  }
  .search-col {
    display: flex;
    align-items: center;
  }
}
</style>
