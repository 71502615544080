<template>
  <div class="popup-approve">
    <b-modal
      id="popup-approve"
      ref="popup-approve"
      hide-footer
      :titleHtml="modalTitle"
      no-close-on-backdrop
      size="xl"
    >
      <ApprovalCommonData
        :mainModel="mainModel"
        :onSubmit="onSubmiting"
        v-if="renderComponent"
        v-on:submit-data="onSubmitData"
      />
      <b-button
        style="fontweight: 600; width: 70px; margin-right: 10px"
        variant="primary"
        size="sm"
        @click="onApprove"
        v-if="mainModel.isCanApprove"
        >Duyệt</b-button
      >
      <b-button
        style="margin-right: 10px; font-weight: 600; width: 100px"
        size="sm"
        variant="warning"
        @click="onCancel"
        v-if="mainModel.isCanApprove"
      >
        Trả phiếu
      </b-button>
      <b-button
        style="font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
    <CancelApproval
      ref="cancel-approval-modal"
      :approvalCode="mainModel.code"
      :approvalId="mainModel.id"
      v-on:cancel-success="onCancelSuccess"
    />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ApprovalCommonData from './ApprovalCommonData.vue';
import CancelApproval from './CancelApproval.vue';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      mainModel: {
        isCanApprove: false,
      },
      renderComponent: false,
      modalTitle: '',
      onSubmiting: false,
    };
  },
  components: {
    ApprovalCommonData,
    CancelApproval,
  },
  validations: {
    form: {
      cancelReason: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showModal: async function (approvalId) {
      this.onSubmiting = false;
      this.renderComponent = false;
      this.mainModel = {
        id: null,
        isCanApprove: false,
      };
      this.mainModel.id = approvalId;
      await this.getApprovalInfoById();
      this.$refs['popup-approve'].show();
    },
    hideModal() {
      this.$refs['popup-approve'].hide();
    },
    onApprove() {
      if (this.onSubmiting) {
        return;
      }
      this.onSubmiting = true;
    },
    onCancel() {
      this.$refs['cancel-approval-modal'].showModal();
    },
    async getApprovalInfoById() {
      await ApiService.get(`approvals/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel = data.data;
          this.renderComponent = true;
          this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Duyệt phiếu: <a class='text-primary'>#${this.mainModel.code}</a></span>
              </div>`;
        })
        .catch((response) => {
          this.mainModel = {
            id: null,
            isCanApprove: false,
          };
          makeToastFaile(response.$error);
        });
    },
    onSubmitData(data) {
      const payload = {
        ...data,
        step: data.currentApproveStep + 1,
      };
      ApiService.put('approvals/approve', payload)
        .then(({ data }) => {
          if (data.status === 200) {
            makeToastSuccess(data.message);
            this.hideModal();
            this.$emit('submit-data-success', true);
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
    onCancelSuccess() {
      this.hideModal();
      this.$emit('submit-data-success', true);
    },
  },
};
</script>

<style></style>
