<template>
  <div class="approval-change-status">
    <b-modal
      ref="approval-change-status"
      hide-footer
      title="Đổi trạng thái phiếu duyệt chi"
      no-close-on-backdrop
      size="lg"
    >
      <b-table
        class="table-common"
        hover
        bordered
        :items="approvalItems"
        :fields="fields"
      >
        <template #cell(statusValidate)="data">
          <div
            v-if="!data.item.onLoading"
            class="d-table-row"
          >
            <div
              class="d-table-cell align-middle"
              style="width: 10%"
            >
              <i
                v-b-tooltip.hover
                :title="htmlGetStatusValidate(data.item, 'title')"
                :class="htmlGetStatusValidate(data.item, 'class')"
              ></i>
            </div>
            <div
              class="d-table-cell align-middle pb-1 pl-2"
              style="width: 90%"
            >
              {{ data.item.statusValidateName }}
            </div>
          </div>
          <div v-else>
            <b-spinner
              small
              label="Small Spinner"
              variant="primary"
              class="mr-1"
            ></b-spinner>
            <span class="text-primary">Đang xử lí...</span>
          </div>
        </template>
        <template #cell(status)="data">
          <b-form-group class="mb-0 d-flex align-items-center">
            <div class="d-table-row">
              <div
                class="d-table-cell align-middle"
                style="width: 40%"
              >
                <label class="font-weight-bold pt-1">
                  {{ getApprovalStatusName(data.item.status) }}
                </label>
              </div>
              <div
                class="d-table-cell align-middle"
                style="width: 10%"
              >
                <i
                  class="fa fa-arrow-right text-primary"
                  aria-hidden="true"
                ></i>
              </div>
              <div
                class="d-table-cell align-middle pb-1"
                style="width: 55%"
              >
                <treeselect
                  :options="optionStatus"
                  :multiple="false"
                  placeholder="Chọn trạng thái"
                  noResultsText="Không có kết quả"
                  :match-keys="['label', 'custom']"
                  v-model="data.item.toStatus"
                  openDirection="bottom"
                  :clearable="false"
                  @select="onSelectStatus"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span :title="node.label"> {{ node.label }}</span>
                  </label>
                </treeselect>
                <div v-if="isShowOption(data.item, 'accountant')">
                  <Autosuggest
                    class="border-radius-none mt-2"
                    :model="handleSearchCash"
                    :suggestions="filteredAccountantOptions"
                    placeholder="tài khoản thanh toán"
                    :limit="200"
                    @select="onSelectedAccountant"
                    @change="onInputChangeAccountant"
                    :disabled="!filteredAccountantOptions.length"
                    suggestionName="suggestionName"
                  />
                </div>
                <span
                  class="tree-select-custom"
                  v-if="isShowOption(data.item, 'fail-reason')"
                >
                <b-input
                  placeholder="Lý do"
                  v-model="data.item.failReason"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                />
                </span>
              </div>
            </div>
          </b-form-group>
        </template>
      </b-table>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
      >Lưu</b-button>
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
      >Hủy</b-button>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { APPROVAL_STEP_STATUS, APPROVAL_PAYMENT_TYPE, ACCOUNTANT_TYPE } from '@/utils/enum';
import { APPROVAL_STATUS } from '@/utils/constants';
import { makeToastFaile, makeToastSuccess, cloneDeep } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { cmdUrl } from '@/utils/apiUrl';
import { map } from 'lodash';
import { checkPermission } from '@/utils/saveDataToLocal';
import { v4 as uuidv4 } from 'uuid';
const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};
const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  REQUIRED: 2,
};

export default {
  data() {
    return {
      fields: [
        {
          key: 'code',
          label: 'Mã',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          formatter: (value) => {
            return `#${value}`;
          },
          tdClass: 'align-middle',
        },
        {
          key: 'status',
          label: 'Đổi trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '50%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'statusValidate',
          label: 'Kiểm tra',
          thStyle: {
            ...thStyleCommon,
            width: '30%',
          },
          tdClass: 'align-middle',
        },
      ],
      approvalItems: [],
      optionStatus: [],
      onSubmitting: false,
      searchAccountant: '',
      filteredAccountantOptions: [],
      accountantOptions: [],
      selectedAccountantId: null,
      selectedAccountantCode: '',
      idempotenceKey: null,
    };
  },
  components: {
    Treeselect,
    Autosuggest,
  },
  computed: {
    handleSearchCash() {
      return this.filteredAccountantOptions.length > 0
        ? this.searchAccountant
        : 'Không có thông tin loại tài khoản tiền mặt';
    },
  },
  created() {
    this.data.failReason = '';
  },
  methods: {
    checkPermission,
    initOptions() {
      const acceptStatusChanges = [
        APPROVAL_STEP_STATUS.PAID,
        APPROVAL_STEP_STATUS.PAY_FAILED,
        APPROVAL_STEP_STATUS.COMPLETED,
        APPROVAL_STEP_STATUS.CHECKED,
      ];
      const options = APPROVAL_STATUS.filter((approveStatus) =>
        acceptStatusChanges.includes(approveStatus.id),
      );
      this.optionStatus = options.reduce((result, element) => {
        result.push({
          id: element.id ? Number(element.id) : null,
          label: element.name,
          customLabel: element.name,
        });
        return result;
      }, []);
    },
    getApprovalStatusName(status) {
      const statusInfo = APPROVAL_STATUS.find((type) => type.id === status);
      return statusInfo ? statusInfo.name : 'Không tìm thấy trạng thái';
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class' ? 'fas fa-check-circle text-success' : 'Hợp lệ';

          break;
        }
        case STATUS_VALIDATE.REQUIRED: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-warning'
              : 'Chưa chọn lí do';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    onSelectStatus(node) {
      const statusChanged = node.id;
      this.approvalItems.map((item) => {
        const validateResult = this.validateChangeStatus(
          item.status,
          statusChanged,
          item.paymentType,
        );
        item.statusValidate = validateResult.statusValidate;
        item.statusValidateName = validateResult.statusValidateName;
        if (statusChanged === APPROVAL_STEP_STATUS.PAID) {
          this.fetchAccountants(ACCOUNTANT_TYPE.CASH, item.storeId);
        }
      });
    },
    showModal: function (data) {
      this.$refs['approval-change-status'].show();
      this.approvalItems = [cloneDeep(data)];
      this.selectedAccountantId = null;
      this.selectedAccountantCode = '';
      this.searchAccountant = '';
      this.initOptions();
    },
    hideModal() {
      this.$refs['approval-change-status'].hide();
    },
    validateChangeStatus(currentStatus, selectedStatus, paymentType) {
      if (currentStatus === selectedStatus) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName: 'Trùng trạng thái',
        };
      }
      if (currentStatus === APPROVAL_STEP_STATUS.CHECKED) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName:
            'Không thể đổi trạng thái phiếu đã hoàn thành hậu kiểm!',
        };
      }
      if (
        selectedStatus === APPROVAL_STEP_STATUS.CHECKED &&
        currentStatus !== APPROVAL_STEP_STATUS.COMPLETED
      ) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName:
            'Chỉ có thể đổi trạng thái đã hậu kiểm cho phiếu đã hoàn thành!',
        };
      }
      if (
        currentStatus === APPROVAL_STEP_STATUS.COMPLETED &&
        selectedStatus !== APPROVAL_STEP_STATUS.CHECKED
      ) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName:
            'Phiếu trạng thái hoàn thành chỉ được cập nhật thành Đã hậu kiểm!',
        };
      }
      if (currentStatus === APPROVAL_STEP_STATUS.CANCELED) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName: 'Không thể đổi trạng thái phiếu đã bị hủy!',
        };
      }
      if (currentStatus === APPROVAL_STEP_STATUS.PAY_FAILED) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName:
            'Không thể đổi trạng thái phiếu thanh toán thất bại!',
        };
      }
      if (
        currentStatus === APPROVAL_STEP_STATUS.PAID &&
        selectedStatus !== APPROVAL_STEP_STATUS.COMPLETED
      ) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName:
            'Chỉ có thể cập nhật Đã hoàn thành cho phiếu Đã thanh toán!',
        };
      }
      if (
        paymentType === APPROVAL_PAYMENT_TYPE.TRANSFER &&
        (selectedStatus === APPROVAL_STEP_STATUS.PAID ||
          selectedStatus === APPROVAL_STEP_STATUS.PAY_FAILED)
      ) {
        if (!this.checkPermission('APPROVAL_PAID')) {
          return {
            statusValidate: STATUS_VALIDATE.INVALID,
            statusValidateName:
              'Bạn không có quyền cập nhật trạng thái thanh toán cho phiếu duyệt chi này!',
          };
        }
      }
      if (
        currentStatus === APPROVAL_STEP_STATUS.APPROVED &&
        selectedStatus === APPROVAL_STEP_STATUS.COMPLETED
      ) {
        return {
          statusValidate: STATUS_VALIDATE.INVALID,
          statusValidateName: 'Vui lòng cập nhật trạng thái thanh toán trước!',
        };
      }
      return {
        statusValidate: STATUS_VALIDATE.VALID,
        statusValidateName: 'Hợp lệ!',
      };
    },
    async onSubmit() {
      if (this.onSubmitting) {
        return;
      }

      for (const approvalItem of this.approvalItems) {
        if (!approvalItem.toStatus) {
          approvalItem.statusValidate = STATUS_VALIDATE.REQUIRED;
          approvalItem.statusValidateName = 'Vui lòng chọn trạng thái cần đổi!';
          makeToastFaile('Vui lòng chọn trạng thái cần đổi!');
          return;
        }
      }
      if (!this.isValidDataSubmit()) {
        return makeToastFaile('Vui lòng chọn trạng thái hợp lệ!');
      }
      for (const approvalItem of this.approvalItems) {
        await this.onCallUpdateStatus(approvalItem);
      }
      this.$emit('submit-data-success', true);
      this.hideModal();
      this.onSubmitting = false;
    },
    async onCallUpdateStatus(data) {
      if (!this.idempotenceKey) {
        this.idempotenceKey = uuidv4();
      }

      const payload = {
        status: data.toStatus,
        accountantId: this.selectedAccountantId,
        accountantName: this.searchAccountant,
        accountantCode: this.selectedAccountantCode,
        failReason: data.failReason,
      };
      this.onSubmitting = true;
      await ApiService.put(`approvals/${data.id}/status`, payload, this.idempotenceKey)
        .then(({ data }) => {
          this.idempotenceKey = uuidv4();
          makeToastSuccess(data.message);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    isValidDataSubmit() {
      const inValidItem = this.approvalItems.find(
        (item) => item.statusValidate !== STATUS_VALIDATE.VALID,
      );
      return inValidItem ? false : true;
    },
    onSelectedAccountant(option) {
      this.searchAccountant = option.item.name;
      this.selectedAccountantId = option.item.id;
      this.selectedAccountantCode = option.item.code;
    },
    onInputChangeAccountant(text) {
      this.searchAccountant = text;
      const filteredData = this.accountantOptions
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredAccountantOptions = [...filteredData];
    },
    fetchAccountants: async function (paymentType, storeId) {
      this.accountantOptions = [];
      let params = {
        type: paymentType,
        storeId: storeId,
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (paymentType === 1) {
        url = 'accountants/getByStore';
        params = {
          type: paymentType,
          storeId: storeId,
        };
      }
      await ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        map(data, (item) => {
          const element = {
            id: item.id,
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          this.accountantOptions.push(element);
        });
        this.filteredAccountantOptions = [...this.accountantOptions];
      });
    },
    isShowOption(item, name) {
      if (item.statusValidate === STATUS_VALIDATE.INVALID) {
        return false;
      }
      if (name === 'accountant') {
        return item.toStatus === APPROVAL_STEP_STATUS.PAID;
      }
      if (name === 'fail-reason') {
        return item.toStatus === APPROVAL_STEP_STATUS.PAY_FAILED;
      }
    },
  },
};
</script>

<style scoped>
.table-common /deep/ .vue-treeselect__input-container {
  padding-top: 0px;
}
.table-common /deep/ .vue-treeselect__control {
  border-radius: none;
  border: none;
  height: 2.5rem;
}

.table-common /deep/ .vue-treeselect__input {
  font-size: 22px;
}
</style>
